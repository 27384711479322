<template>
  <div>
    <div class="row justify-content-center">
      <div class="my-3">
        <div class="col-md-12 my-5">
          <Form
            class=""
            v-slot="{ errors }"
            @submit="orderSubmit"
            ref="disabledd"
            @invalid-submit="onInvalidSubmit"
          >
            <!-- 訂購內容 .-->
            <div class="bg-white mb-4">
              <h4 class="px-3 px-md-4 py-3 border-bottom border-dark border-2">
                訂購內容 ( {{ this.productNum }} 件)
              </h4>
              <table class="table table-borderless mb-0">
                <thead>
                  <tr class="border-bottom d-none d-lg-table-row">
                    <th colspan="2" scope="col" width="55%" class="ps-md-5 py-md-3">
                      商品資料
                    </th>
                    <th scope="col" class="py-md-3 text-center">單價</th>
                    <th scope="col" class="py-md-3 text-center" width="20%">數量</th>
                    <th scope="col" class="py-md-3 text-center">小計</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="cartBorderBottom ps-3 ps-lg-5 pb-0 pt-2 pt-lg-3 pb-lg-3 w-30 w-lg-15"
                  >
                    <td class="ps-3 ps-lg-5 pb-2 pt-2 pt-lg-3 pb-lg-3 w-30 w-lg-15">
                      <img
                        class="img-fluid w-100"
                        :src="product.Image"
                        alt="product image"
                      />
                    </td>
                    <td class="py-md-3">
                      <router-link
                        :to="`/birthdaygift/${product.Id}`"
                        class="d-block mt-3 mt-md-0"
                      >
                        <p>{{ product.Name }}</p>
                      </router-link>
                    </td>
                    <td class="py-md-3 d-none d-lg-table-cell text-center px-0 px-xl-2">
                      $ {{ $currency.currency(product.Price) }}
                    </td>
                    <td class="py-md-3 d-none d-lg-table-cell">
                      <div class="productNumber input-group">
                        <button
                          type="button"
                          class="btn border-dark rounded-0"
                          @click.prevent="minusNum(product)"
                          :class="{ disabled: productNum === 1 }"
                        >
                          -
                        </button>
                        <input
                          type="number"
                          class="form-control border-dark text-center"
                          aria-label="product number"
                          onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')"
                          v-model.number="productNum"
                          readonly
                        />
                        <button
                          type="button"
                          class="btn border-dark rounded-0"
                          @click.prevent="addNum(product)"
                          :class="{ disabled: productNum === 5 }"
                        >
                          +
                        </button>
                      </div>
                    </td>
                    <td class="py-md-3 d-none d-lg-table-cell text-center">
                      $ {{ $currency.currency(product.Price * this.productNum) }}
                    </td>
                    <td class="py-md-3 text-center">
                      <button
                        type="button"
                        class="btn fs-5 mt-2 mt-md-0"
                        @click.prevent="deleteProduct(product)"
                      >
                        <i class="far fa-trash-alt"></i>
                      </button>
                    </td>
                  </tr>
                  <tr class="d-lg-none">
                    <td colspan="6" class="text-end fw-light px-3 pt-0 pb-2">
                      $ {{ $currency.currency(product.Price) }}
                    </td>
                  </tr>
                  <tr class="border-bottom d-lg-none">
                    <td colspan="6" class="px-3 pb-3 pt-0">
                      <div class="d-flex justify-content-end">
                        <div class="productNumber input-group w-45">
                          <button
                            type="button"
                            class="btn border-dark rounded-0"
                            @click.prevent="minusNum(product)"
                            :class="{ disabled: productNum === 1 }"
                          >
                            -
                          </button>
                          <input
                            type="number"
                            class="form-control border-dark text-center py-1"
                            aria-label="product number"
                            onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')"
                            v-model.number="productNum"
                            readonly
                          />
                          <button
                            type="button"
                            class="btn border-dark rounded-0"
                            @click.prevent="addNum(product)"
                            :class="{ disabled: productNum === 5 }"
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- 收件人資料 -->
            <div class="card rounded-0 mt-3 mt-md-5">
              <div
                class="card-header fw-bold bg-white px-4 py-3 border-bottom border-dark border-2"
              >
                <div class="row align-items-center">
                  <div class="col-4 col-md-2">
                    <h4 class="text-nowrap mb-2">收件人資料</h4>
                  </div>
                </div>
              </div>
              <div class="card-body px-3 py-4 p-lg-5">
                <div class="row mb-3">
                  <label for="receiverName" class="col-2 col-form-label">姓名</label>
                  <div class="col-10 col-md-4">
                    <Field
                      type="text"
                      name="姓名"
                      class="form-control"
                      id="receiverName"
                      placeholder="請填入姓名"
                      rules="required"
                      v-model="checkOrder.consignee.consigneeName"
                      :class="{ 'is-invalid': errors['姓名'] }"
                    ></Field>
                    <ErrorMessage name="姓名" class="invalid-feedback"> </ErrorMessage>
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="receiverPhone" class="col-2 col-form-label">手機</label>
                  <div class="col-10 col-md-4">
                    <Field
                      type="text"
                      name="手機"
                      class="form-control"
                      id="receiverPhone"
                      placeholder="請填入手機"
                      :rules="isPhone"
                      :class="{ 'is-invalid': errors['手機'] }"
                      v-model="checkOrder.consignee.consigneeCellphone"
                    ></Field>
                    <ErrorMessage name="手機" class="invalid-feedback"> </ErrorMessage>
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="receiverAddress" class="col-2 col-md-2 col-form-label"
                    >寄送地址</label
                  >
                  <div class="col-10 col-md-10">
                    <div class="row g-2">
                      <div class="col-6 col-md-3">
                        <Field
                          type="text"
                          name="縣市"
                          id="country"
                          value=""
                          class="form-select pe-xl-2"
                          as="select"
                          @change="getTown"
                          v-model="checkOrder.consignee.consigneeCity"
                          rules="required"
                          :class="{ 'is-invalid': errors['縣市'] }"
                        >
                          <option value="" disabled selected>縣市</option>
                          <option
                            :value="country.Country"
                            v-for="country in addressList.countryList"
                            :key="country"
                          >
                            {{ country.CountryName }}
                          </option>
                        </Field>
                        <ErrorMessage name="縣市" class="invalid-feedback">
                        </ErrorMessage>
                      </div>
                      <div class="col-6 col-md-3">
                        <Field
                          type="text"
                          name="區名"
                          id="town"
                          value=""
                          class="form-select pe-xl-2"
                          v-model="checkOrder.consignee.consigneeDistrict"
                          as="select"
                          rules="required"
                          :class="{ 'is-invalid': errors['區名'] }"
                        >
                          <option value="" disabled selected>區名</option>
                          <option
                            v-for="town in addressList.townList"
                            :key="town"
                            :value="town.District"
                          >
                            {{ town.DistrictName }}
                          </option>
                        </Field>
                        <ErrorMessage name="區名" class="invalid-feedback">
                        </ErrorMessage>
                      </div>
                      <div class="col mt-2">
                        <Field
                          type="text"
                          name="詳細地址"
                          class="form-control"
                          rules="required"
                          v-model.trim="checkOrder.consignee.consigneeOtherAddress"
                          placeholder="請輸入詳細地址"
                          :class="{ 'is-invalid': errors['詳細地址'] }"
                        >
                        </Field>
                        <ErrorMessage name="詳細地址" class="invalid-feedback">
                        </ErrorMessage>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-3 align-items-center">
                  <label for="receiverEmail" class="col-2 col-md-2 col-form-label"
                    >電子郵件</label
                  >
                  <div class="col-10 col-md-10">
                    <Field
                      type="email"
                      name="電子郵件"
                      class="form-control"
                      rules="required"
                      v-model="checkOrder.consignee.consigneeEmail"
                      id="receiverEmail"
                      placeholder="請填入 Email"
                      :class="{ 'is-invalid': errors['電子郵件'] }"
                    >
                    </Field>
                    <ErrorMessage name="電子郵件" class="invalid-feedback">
                    </ErrorMessage>
                  </div>
                </div>
                <div class="row mb-3 align-items-center">
                  <label for="receiverMemo" class="col-2 col-md-2 col-form-label"
                    >備註</label
                  >
                  <div class="col-10">
                    <textarea
                      type="text"
                      rows="3"
                      class="form-control"
                      id="receiverMemo"
                      maxlength="50"
                      placeholder="內容限50字"
                      style="resize: none"
                      v-model="this.checkOrder.Memo"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- 訂單資訊 -->
            <div class="card rounded-0 mt-3 mt-md-5">
              <div class="bg-white mb-3">
                <h4 class="px-3 px-md-4 py-3 border-bottom border-dark border-2">
                  訂單資訊
                </h4>
                <div class="border-bottom border-dark border-1">
                  <div class="d-flex justify-content-between px-3 pt-3 pb-2 px-md-5">
                    <p>商品總金額</p>
                    <p>$ {{ $currency.currency(product.Price * this.productNum) }}</p>
                  </div>
                </div>
                <div class="d-flex justify-content-between px-3 py-3 px-md-5">
                  <p>合計( {{ this.productNum }} 件)</p>
                  <p class="text-primary">
                    $ {{ $currency.currency(product.Price * this.productNum) }}
                  </p>
                </div>
              </div>
            </div>
            <!-- 付款方式及紅利折抵 -->
            <div class="card rounded-0 mt-3 mt-md-5">
              <div class="bg-white">
                <h4 class="px-3 px-md-4 py-3 border-bottom border-dark border-2">
                  選擇付款方式及紅利折抵
                  <span class="fs-7 fs-xl-6 d-block d-xl-inline ms-xl-2 mt-2"
                    >※若您有聯邦信用卡紅利點數但未顯示，請您參考聯邦紅利卡友兌換說明</span
                  >
                </h4>
                <div
                  v-for="payments in paymentList"
                  :key="payments"
                  class="bg-white px-2 py-3 px-md-5 border-bottom"
                >
                  <div class="form-check">
                    <Field
                      class="form-check-input me-2 me-md-3 checks"
                      type="radio"
                      name="paymentRadio"
                      :id="payments.id"
                      v-model="paymentMethod"
                      :value="payments.id"
                    />
                    <label class="form-check-label" :for="payments.id">
                      {{ payments.name }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <!--發票資訊 -->
            <div class="card receipt-card rounded-0 mt-3 mt-md-5">
              <div
                class="card-header fw-bold bg-white px-4 py-3 border-bottom border-dark border-2"
              >
                <h4>發票資訊</h4>
              </div>
              <div class="card-body px-3 py-3 px-lg-5">
                <!-- 1.捐贈發票 -->
                <div class="form-check border-bottom pb-3">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="receipt"
                    id="donate"
                    value="OI03/"
                    v-model="checkReceiver.receipt.method"
                    @change="reFreshTwoThree"
                  />
                  <label class="form-check-label" for="donate">
                    捐贈 ( 捐給創世基金會 )
                  </label>
                </div>
                <!-- 2.二聯式發票 -->
                <div class="border-bottom d-lg-flex py-3 align-items-center">
                  <p
                    class="receiptTitle bg-gray text-black text-center px-2 d-inline-block"
                  >
                    二聯式發票
                  </p>
                  <div class="row align-items-center ps-0 ps-lg-3 w-100 ms-lg-2">
                    <div class="col-xl-2 mt-2 mt-lg-0">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="receipt"
                          id="memberCarrier"
                          value="OI01/1"
                          v-model="checkReceiver.receipt.method"
                          @change="reFreshThree_1"
                          checked
                        />
                        <label class="form-check-label" for="memberCarrier">
                          個人會員載具
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 3.三聯式發票 -->
                <div class="border-bottom d-lg-flex py-3 align-items-center">
                  <p
                    class="receiptTitle bg-gray text-black text-center px-2 d-inline-block"
                  >
                    三聯式發票
                  </p>
                  <div class="row align-items-center ps-0 ps-lg-3 w-100 ms-xl-2">
                    <div class="col-xl-2 mt-2 mt-lg-0">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="receipt"
                          id="companyCarrier"
                          value="OI02/1"
                          v-model="checkReceiver.receipt.method"
                          @change="reFreshTwo"
                        />
                        <label class="form-check-label" for="companyCarrier">
                          公司會員載具
                        </label>
                      </div>
                    </div>
                    <div class="col-xl-4">
                      <div class="d-xl-flex align-items-center">
                        <label for="companyId" class="col-form-label py-0 mx-md-1"
                          >統一編號</label
                        >
                        <div class="d-flex flex-column ms-1 flex-fill">
                          <Field
                            type="text"
                            class="form-control"
                            id="companyId"
                            name="統一編號"
                            :rules="isTaxIdNumber"
                            v-model.trim="checkOrder.Invoice.BizAdmNo"
                            :class="{ 'is-invalid': errors['統一編號'] }"
                            @change="isTaxIdNumber2"
                            @keyup="reFreshTwo"
                            @clipboardData="reFreshTwo"
                          >
                          </Field>
                          <ErrorMessage
                            name="統一編號"
                            class="invalid-feedback clearInputMsg"
                          >
                          </ErrorMessage>
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-xl-1 d-lg-block d-flex justify-content-end align-items-center"
                    >
                      <button
                        class="d-block btn me-md-2 me-1 shadow-none border-secondary text-nowrap mt-2 mt-lg-0 fs-7 p-md-1 p-1 btn-sm btn-secondary text-white"
                        @click.prevent="checkTaxNumberExist"
                        style="margin-left: -16px"
                      >
                        帶入公司名
                      </button>
                    </div>
                    <div class="col-xl-5">
                      <div class="d-xl-flex align-items-center">
                        <label for="companyName" class="col-form-label py-0 me-md-1"
                          >公司名稱</label
                        >
                        <div class="d-flex flex-column ms-1 flex-fill">
                          <Field
                            type="text"
                            class="form-control"
                            id="companyName"
                            name="公司名稱"
                            :rules="isCompanyName"
                            v-model.trim="checkOrder.Invoice.companyName"
                            :class="{ 'is-invalid': errors['公司名稱'] }"
                            ref=""
                            @keyup="reFreshTwo"
                          ></Field>
                          <ErrorMessage
                            name="公司名稱"
                            class="invalid-feedback clearInputMsg"
                          >
                          </ErrorMessage>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-check mt-4 mt-ld-5">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="agreementChecked"
                    name="必須同意"
                    v-model="must"
                  />
                  <label class="form-check-label fs-6 fs-md-5" for="agreementChecked">
                    我同意辦理退貨時，由 Papawash
                    代為處理發票及銷貨退回證明單，以加速退貨退款作業。
                  </label>
                </div>
                <p class="p-1 fs-7 fs-md-6">
                  依統一發票使用辦法規定， 個人發票一經開立，不得更改或改開公司戶發票。
                  <a
                    href="https://www.einvoice.nat.gov.tw/"
                    class="finacial text-decoration-underline text-nowrap"
                    >財政部電子發票流程說明</a
                  >
                </p>
              </div>
            </div>
            <!-- 到第二頁 填寫資料 -->
            <div class="row justify-content-center my-4">
              <div class="col-lg-6">
                <div class="d-grid gap-2">
                  <button class="btn btn-primary rounded-0 fs-6 fs-lg-5" type="submit">
                    確定
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
    <!-- loading 動畫 -->
    <loading
      :active="isLoading"
      class="bottom-fixed position-absolute top-50 start-50 translate-middle"
      id="overlay"
      backgroundColor="transparent"
      blur=""
    >
      <div class="loading1">
        <div class="demo1"></div>
      </div>
    </loading>
  </div>
</template>

<script>
import ProductSvc from '@/service/product-service.js'
import countryName from '@/assets/country.json'

export default {
  data () {
    return {
      product: {}, //* 訂購內容
      // productId: 'P220615000001',
      productNum: '1',
      sendingOrder: false /* loading動畫 */,
      must: true,
      correctInvoice: false,
      TaxIdNumber: [
        /* 統一編號驗證邏輯乘數 */
        1,
        2,
        1,
        2,
        1,
        2,
        4,
        1
      ],
      countTaxNumber: [],
      finalPrice: 0,
      countryName, //* 縣市鄉鎮代碼 json
      //* 地址選單
      addressList: {
        countryList: [],
        townList: []
      },
      //* 付款方式
      paymentList: [
        {
          id: 'P03',
          name: '聯邦卡友紅利折抵及付款'
        },
        {
          id: 'P04',
          name: '聯邦卡一次付清'
        },
        {
          id: 'P07',
          name: '他行卡一次付清'
        },
        {
          id: 'P05',
          name: 'ATM 轉帳'
        },
        {
          id: 'P10',
          name: '貨到付款'
        },
        {
          id: 'P09',
          name: 'LINE Pay(可使用 LINE POINTS 折抵消費)'
        }
      ],
      checkReceiver: {
        //* 暫時性接值物件
        name: '',
        phone: '',
        email: '',
        receiveAddress: {
          countryId: '',
          townId: '',
          addressDetail: ''
        },
        receipt: {
          method: 'OI01/1', //* 預設checked 為會員載具
          myVehicle: '',
          coVehicle: '',
          coName: '',
          taxIdNumber: ''
        }
      },
      //*  訂單送出時要post的物件
      checkOrder: {
        payInfo: {}, //* 付款資訊
        buyer: {
          buyerName: '',
          buyerCellphone: '',
          buyerCity: '',
          buyerDistrict: '',
          buyerOtherAddress: '',
          buyerEmail: ''
        },
        //* 收件人資料
        consignee: {
          consigneeName: '',
          consigneeCellphone: '',
          consigneeCity: '',
          consigneeDistrict: '',
          consigneeOtherAddress: '',
          consigneeEmail: ''
        },
        //* 發票資訊
        Invoice: {
          invoiceType: '',
          invoiceMark: '',
          carrierType: '',
          carrierCode: '',
          companyName: '',
          BizAdmNo: ''
        },
        Memo: '',
        items: [],
        OrderGifts: null
      },
      paymentMethod: '' //* 付款方式
    }
  },
  methods: {
    //* 取得購物車
    GetBirthCheckoutCart () {
      // this.productId = this.$route.params.id
      ProductSvc.GetBirthProduct_2025().then((data) => {
        this.isLoading = true
        if (!data) {
          return
        }
        this.product = data
        // * GA追蹤程式
        // this.title.textContent = `papaWash - ${this.product.Name}` // ?title更新為當頁商品
        // this.track() // ? 呼叫vue-gTag傳入 title
        if (this.product.Options.length > 0) {
          this.optionSelected = this.product.Options[0]
          if (this.product.Options[0].Stock === 0) {
            this.productNum = 0
          }
        }
        setTimeout(() => {
          const url = document.querySelectorAll('.descriptionContent img')
          url.forEach((item) => {
            item.classList.add('w-100')
          })
          const url2 = document.querySelectorAll('.specificationContent img')
          url2.forEach((item) => {
            item.classList.add('w-100')
          })
        }, 500)
        // this.isLoading = false
      })
    },
    //* 商品數量增加
    addNum (pd) {
      this.productNum++
    },
    //* 商品數量減少
    minusNum (pd) {
      if (this.productNum > 1) {
        this.productNum--
      }
    },
    //* 商品移出購物車
    deleteProduct (pd) {
      this.$swal
        .fire({
          title: '刪除產品',
          text: '您確定要刪除?',
          showCancelButton: true,
          confirmButtonColor: '#003894',
          cancelButtonColor: '#4D4D4D',
          confirmButtonText: '確定刪除',
          cancelButtonText: '取消',
          reverseButtons: true
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.rewards = 0
            this.$router.push(`/birthdaygift/${this.productId}`)
          }
        })
    },
    //* 驗證手機載具格式(用套件的方式，不易改寫)
    isMyVehicle (value) {
      if (this.checkReceiver.receipt.method === 'OI01/2/3J0002') {
        const MyVehicleNumber = /^\/[0-9A-Z.+-]{7}$/
        return MyVehicleNumber.test(value) ? true : '請輸入含/8碼載具格式'
      } else {
        return true
      }
    },
    //* 驗證自然人載具格式(用套件的方式，不易改寫)
    isNatureVehicle (value) {
      /* 驗證自然人載具格式 */
      if (this.checkReceiver.receipt.method === 'OI01/2/CQ0001') {
        const NatureVehicleNumber = /^[a-zA-Z]{2}[0-9]{14}$/
        return NatureVehicleNumber.test(value) ? true : '請輸入16碼載具格式'
      } else {
        return true
      }
    },
    //* 驗證公司名稱(用套件的方式，不易改寫)
    isCompanyName (value) {
      if (this.checkReceiver.receipt.method === 'OI02/1') {
        return value ? true : '請輸入公司名稱'
      } else {
        return true
      }
    },
    //* 驗證統一編號step1-正規式(用套件的方式，不易改寫)
    isTaxIdNumber (value) {
      if (this.checkReceiver.receipt.method === 'OI02/1') {
        const TaxIdNumber = /^[0-9]{8}$/
        return TaxIdNumber.test(value) ? true : '請輸入正確格式'
      } else {
        return true
      }
    },
    //* 驗證統一編號step2-公式檢驗(112年4月後,因空號將於113年用罄須修改邏輯參數)
    isTaxIdNumber2 () {
      this.countTaxNcountTaxNumber2not7umber = this.checkOrder.Invoice.BizAdmNo.split(
        ''
      ).map(Number)
      if (this.countTaxNumber[6] === 7) {
        this.countTaxNumber2is7()
      } else if (this.countTaxNumber[6] !== 7) {
        this.countTaxNumber2not7()
      }
    },
    //* 驗證統一編號step2-公式檢驗:可能性1-倒數第二碼非7
    countTaxNumber2not7 () {
      this.sum = []
      this.countTaxNumber.forEach((item, index) => {
        item = item * this.TaxIdNumber[index]
        if (item >= 10) {
          item = (item % 10) + Math.floor(item / 10)
          this.sum.push(item)
        } else {
          this.sum.push(item)
        }
      })
      this.finaltotal = 0
      this.sum.forEach((item) => {
        this.finaltotal += item
      })
      if (this.finaltotal % 10 === 0) {
        this.correctInvoice = true
      } else {
        this.correctInvoice = false
      }
    },
    //* 驗證統一編號step2-公式檢驗:可能性2-倒數第二碼為7
    countTaxNumber2is7 () {
      this.sum = []
      this.countTaxNumber.forEach((item, index) => {
        item = item * this.TaxIdNumber[index]
        if (item >= 10) {
          item = (item % 10) + Math.floor(item / 10)
          this.sum.push(item)
        } else {
          this.sum.push(item)
        }
      })
      this.sum[6] = 1
      this.sumTax_1 = [...this.sum]
      this.sum[6] = 0
      this.sumTax_2 = [...this.sum]
      let finaltotal2 = 0
      this.sumTax_1.forEach((item) => {
        finaltotal2 = item + finaltotal2
      })
      let finaltotal3 = 0
      this.sumTax_2.forEach((item) => {
        finaltotal3 = item + finaltotal3
      })
      if (finaltotal2 % 10 === 0 || finaltotal3 % 10 === 0) {
        this.correctInvoice = true
      } else {
        this.correctInvoice = false
      }
    },
    //* 按鈕帶入公司名稱並驗證統編是否存在
    checkTaxNumberExist () {
      if (this.correctInvoice === false) {
        this.$swal.fire({
          title: '統一編號格式有誤',
          showConfirmButton: false,
          timer: 2500,
          customClass: {
            title: 'text-class'
          }
        })
        return
      }
      const TaxIdNumberApi = `${process.env.VUE_APP_API}/api/order/getcompanyname?bizAdmNo=${this.checkOrder.Invoice.BizAdmNo}`
      this.axios.get(TaxIdNumberApi).then((res) => {
        if (res.data.rtnCode === 0) {
          /* 帶入對應公司名稱 */
          this.checkOrder.Invoice.companyName = res.data.info.bizAdmName
          this.$swal.fire({
            title: '統編對應公司名稱已帶入',
            showConfirmButton: true,
            confirmButtonColor: '#003894',
            width: 400,
            // timer: 1500,
            customClass: {
              title: 'text-class'
            }
          })
        } else {
          this.$swal.fire({
            title: `該統編格式正確，<br>但${res.data.rtnMsg}(錯誤代碼${res.data.rtnCode})，請手動輸入!`,
            showConfirmButton: true,
            confirmButtonColor: '#003894',
            width: 400,
            // timer: 5500,
            customClass: {
              title: 'text-class'
            }
          })
        }
      })
    },
    //* 產生縣市清單
    getCountry () {
      const list = new Set()
      countryName.forEach((item) => {
        list.add(item.CountryName)
      })
      const listAry = [...list]
      const list2 = []
      listAry.forEach((item) => {
        const num = this.countryName.findIndex((item2) => item2.CountryName === item)
        list2.push({
          Country: this.countryName[num].Country,
          CountryName: item
        })
      })
      this.addressList.countryList = list2
    },
    //* 產生鄉鎮清單(收件人縣市)
    getTown () {
      this.addressList.townList = this.countryName.filter(
        (item) => item.Country === this.checkOrder.consignee.consigneeCity
      )
    },
    //* 驗證手機格式
    isPhone (value) {
      /* 驗證手機 */
      const phoneNumber = /^(09)[0-9]{8}$/
      return phoneNumber.test(value) ? true : '請輸入9碼手機格式'
    },
    // *FBQ追蹤碼(開始結帳)
    fbq_checkout () {
      const toFbqData = {
        contents: [],
        content_type: 'product',
        value: this.finalPrice,
        currency: 'TWD'
      }
      toFbqData.contents = JSON.parse(JSON.stringify(this.cartList.Products))
      toFbqData.contents.forEach((item) => {
        item.id = item.Id
        item.quantity = item.Quantity
        delete item.Gift
        delete item.Id
        delete item.ImageUrl
        delete item.Name
        delete item.OptionId
        delete item.OptionName
        delete item.Price
        delete item.Quantity
        delete item.Stock
      })
      window.fbq('track', 'InitiateCheckout', toFbqData)
    },
    //* 總表單驗證
    onInvalidSubmit ({ values, errors, results }) {
      this.$swal.fire({
        title: '尚有必填欄位未填寫',
        showConfirmButton: false,
        timer: 2500,
        customClass: {
          title: 'text-class'
        }
      })
    },
    reFreshTwo () {
      this.checkReceiver.receipt.method = 'OI02/1' // ?值為公司會員載具
      // console.log('刷新第二聯')
      this.$nextTick(() => {
        this.checkReceiver.receipt.myVehicle = ''
        this.checkReceiver.receipt.coVehicle = ''
      })
    },
    reFreshThree_1 () {
      // console.log('刷新第三聯')
      this.$nextTick(() => {
        this.checkOrder.Invoice.BizAdmNo = ''
        this.checkOrder.Invoice.companyName = ''
        this.checkReceiver.receipt.myVehicle = ''
        this.checkReceiver.receipt.coVehicle = ''
      })
    },
    reFreshThree_2 () {
      this.checkReceiver.receipt.method = 'OI01/2/3J0002' // ?值為[二聯式發票]手機會員載具
      // console.log('刷新第三聯')
      this.$nextTick(() => {
        this.checkOrder.Invoice.BizAdmNo = ''
        this.checkOrder.Invoice.companyName = ''
        this.checkReceiver.receipt.coVehicle = ''
      })
    },
    reFreshThree_3 () {
      this.checkReceiver.receipt.method = 'OI01/2/CQ0001' // ?值為[二聯式發票]自然人憑證載具
      // console.log('刷新第三聯')
      this.$nextTick(() => {
        this.checkOrder.Invoice.BizAdmNo = ''
        this.checkOrder.Invoice.companyName = ''
        this.checkReceiver.receipt.myVehicle = ''
      })
    },
    reFreshTwoThree () {
      // ? 刷新頁面
      // console.log('刷新第二三聯')
      this.$nextTick(() => {
        this.checkReceiver.receipt.myVehicle = ''
        this.checkReceiver.receipt.coVehicle = ''
        this.checkOrder.Invoice.BizAdmNo = ''
        this.checkOrder.Invoice.companyName = ''
      })
    },
    diverseTradeUnsucess (data) {
      // ?帶入後端回應各種交易失敗情境
      this.$swal
        .fire({
          title: `${data.rtnMsg}(${data.rtnCode})`,
          width: 500,
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonColor: '#003894',
          CancelButtonColor: '#4D4D4D',
          confirmButtonText: '確認',
          cancelButtonText: '回到生日禮',
          customClass: {
            title: 'text-class',
            showConfirmButton: 'center',
            showCancelButton: 'center'
          }
        })
        .then((result) => {
          if (result.isConfirmed) {
            // ?留原頁，回頂部
            this.$router.push(`/checkoutboard/birthdaycheckoutcart/${this.productId}`)
          } else if (result.dismiss === this.$swal.DismissReason.cancel) {
            // ?回到生日禮頁面
            this.$router.push(`/birthdaygift/${this.productId}`)
          }
        })
    },
    //* 訂單第一步驟-送出訂單，是否有勾選核取方塊
    orderSubmit (value) {
      if (!this.paymentMethod) {
        this.$swal.fire({
          title: '請選擇付款方式',
          allowOutsideClick: true,
          confirmButtonColor: '#003894',
          confirmButtonText: '確認',
          width: 400,
          customClass: {
            title: 'text-class',
            confirmButton: 'confirm-btn-class'
          }
        })
        return
      }

      this.checkOrder.items = [
        {
          productId: this.product.Id,
          optionId: this.product.Options[0].Id,
          giftId: '',
          price: this.product.Price,
          quantity: this.productNum
        }
      ]
      this.checkOrder.payInfo = {
        paidAmount: this.product.Price * this.productNum,
        rewardMoney: 0,
        pointDiscount: 0,
        couponId: '',
        paymentMethod: this.paymentMethod
      }
      this.checkOrder.buyer.buyerName = this.checkOrder.consignee.consigneeName
      this.checkOrder.buyer.buyerCellphone = this.checkOrder.consignee.consigneeCellphone
      this.checkOrder.buyer.buyerCity = this.checkOrder.consignee.consigneeCity
      this.checkOrder.buyer.buyerDistrict = this.checkOrder.consignee.consigneeDistrict
      this.checkOrder.buyer.buyerOtherAddress = this.checkOrder.consignee.consigneeOtherAddress
      this.checkOrder.buyer.buyerEmail = this.checkOrder.consignee.consigneeEmail
      this.getTown()
      if (this.must === false) {
        this.$swal.fire({
          title: '請勾選退貨辦法同意說明',
          showConfirmButton: false,
          timer: 2500,
          customClass: {
            title: 'text-class'
          }
        })
        return
      }
      // ? 訂單條件同時是未滿$2000+送貨地點在離島區域+貨到付款
      if (
        this.checkOrder.payInfo.paidAmount < 2000 &&
        (this.checkOrder.consignee.consigneeCity === '20' ||
          this.checkOrder.consignee.consigneeCity === '24' ||
          this.checkOrder.consignee.consigneeCity === '25') &&
        this.checkOrder.payInfo.paymentMethod === 'P10'
      ) {
        this.$swal.fire({
          title:
            '<strong style="color:red; font-size: 15px;">離島地區(澎湖縣、金門縣、連江縣)需滿2000元，才可使用貨到付款</strong>',
          showConfirmButton: false,
          timer: 2500,
          customClass: {
            title: 'text-class'
          }
        })
        return null
      } else if (this.checkReceiver.receipt.method === 'OI02/1') {
        // ?統一編號邏輯驗證並call api查證
        this.isTaxIdNumber2() // ?若是庫存不足自動帶入訂單資訊也要觸發運算
        if (this.correctInvoice === false) {
          this.$swal.fire({
            title: '統一編號格式有誤',
            showConfirmButton: false,
            timer: 2500,
            customClass: {
              title: 'text-class'
            }
          })
          return
        }
        this.dataHandlerandSender()
      } else if (this.checkReceiver.receipt.method === 'OI01/2/CQ0001') {
        // ? 自然人載具call api查證
        this.dataHandlerandSender()
        /* 自然人載具無須串聯API驗證由後端回應狀態碼，不個別驗證 */
      } else if (this.checkReceiver.receipt.method === 'OI01/2/3J0002') {
        // ? 手機條碼載具call api查證
        this.dataHandlerandSender()
      } else {
        // ?選擇捐贈或者會員載具 不須callAPI檢查
        this.dataHandlerandSender()
      }
    },
    //* 訂單第二、三步驟
    dataHandlerandSender () {
      /* 2.資料整理 */
      this.checkOrder.Invoice.invoiceType = this.checkReceiver.receipt.method.split(
        '/'
      )[0]
      // console.log(this.checkOrder.Invoice.invoiceType)
      this.checkOrder.Invoice.invoiceMark = this.checkReceiver.receipt.method.split(
        '/'
      )[1]
      if (this.checkOrder.Invoice.invoiceMark === '2') {
        this.checkOrder.Invoice.carrierType = this.checkReceiver.receipt.method.split(
          '/'
        )[2]
        if (this.checkOrder.Invoice.carrierType === '3J0002') {
          this.checkOrder.Invoice.carrierCode = this.checkReceiver.receipt.myVehicle
        } else if (this.checkOrder.Invoice.carrierType === 'CQ0001') {
          this.checkOrder.Invoice.carrierCode = this.checkReceiver.receipt.coVehicle
        }
      }
      this.$swal.fire({
        position: 'center',
        title: '訂單處理中',
        showConfirmButton: false,
        width: 400,
        customClass: {
          title: 'text-class'
        }
      })
      /* 3.送訂單post */
      this.sendingOrder = true
      // console.log(this.checkOrder)
      const url = `${process.env.VUE_APP_API}/api/NewOrder/CreateOrderWithoutToken`
      this.$http
        .post(url, this.checkOrder)
        .then((res) => {
          this.$swal.close()
          if (res.data.info.redirectUrl) {
            this.$swal.fire({
              title: '前往支付交易介面，請勿關閉頁面',
              showConfirmButton: false,
              width: 600,
              customClass: {
                title: 'text-class'
              }
            })
            setTimeout(() => {
              window.location = res.data.info.redirectUrl
            }, 500)
          } else if (res.data.info.orderNo && !res.data.info.redirectUrl) {
            this.$swal.fire({
              title: '前往支付介面，請勿關閉頁面',
              showConfirmButton: false,
              width: 600,
              timer: 2500,
              customClass: {
                title: 'text-class'
              }
            })
            this.$swal.close()
            this.$router.push(
              `/checkoutboard/ordercomplete/${res.data.info.orderNo}/${res.data.info.shoppingDate}`
            )
          } else {
            // ?依據狀態碼有各種失敗的情況
            if (res.request.status === 200) {
              this.diverseTradeUnsucess(res.data)
            } else {
              this.$swal.fire({
                title: `${res.request.status} 系統發生錯誤請聯絡客服人員`,
                showConfirmButton: true,
                confirmButtonColor: '#003894',
                width: 400,
                timer: 4500,
                customClass: {
                  title: 'text-class'
                }
              })
            }
          }
        })
        .catch((err) => {
          if (err) {
            if (err.response.status !== 401) {
              setTimeout(() => {
                this.$swal.close()
              }, 2500)
              this.$swal.fire({
                title: `${err.response.status} 系統發生錯誤請聯絡客服人員`,
                showConfirmButton: true,
                confirmButtonColor: '#003894',
                width: 400,
                timer: 3500,
                customClass: {
                  title: 'text-class'
                }
              })
            }
          }
        })
    },
    GA_remove_from_cart (pd) {
      const dataForGA = {
        items: [{}]
      }
      // !處理資料
      dataForGA.items[0] = JSON.parse(JSON.stringify(pd))
      dataForGA.items[0].item_id = dataForGA.items[0].Id // ?產品代碼
      dataForGA.items[0].item_name = dataForGA.items[0].Name // ?產品名稱
      dataForGA.items[0].price = dataForGA.items[0].Price // ?末端售價
      dataForGA.items[0].quantity = dataForGA.items[0].Quantity // ?庫存
      delete dataForGA.items[0].ImageUrl
      delete dataForGA.items[0].Id
      delete dataForGA.items[0].Name
      delete dataForGA.items[0].OptionId
      delete dataForGA.items[0].OptionName
      delete dataForGA.items[0].Gift
      delete dataForGA.items[0].Price
      delete dataForGA.items[0].Quantity
      delete dataForGA.items[0].Stock
      // !設定事件
      this.$gtag.event('remove_from_cart', dataForGA)
    },
    GA_begin_checkout () {
      const dataForGA = {
        currency: 'TWD',
        value: this.finalPrice, // ? 總金額
        items: [{}]
      }
      dataForGA.items = JSON.parse(JSON.stringify(this.cartList.Products))
      dataForGA.items.forEach((item) => {
        item.item_id = item.productId // ?產品代碼
        item.item_name = item.Name // ?產品名稱
        item.price = item.Price // ?末端售價
        delete item.productId
        delete item.Name
        delete item.Stock
        delete item.Price
        delete item.optionId
        delete item.giftId
      })
      // !設定事件
      this.$gtag.event('begin_checkout', dataForGA)
    }
  },
  watch: {
    $route (to) {
      if (to.fullPath.match('/birthdaygift/')) {
        this.productId = this.$route.params.id
        this.GetBirthCheckoutCart()
      }
    },
    // ?監聽radio input值的變化補足validate的UI體驗
    'checkReceiver.receipt.method' () {
      this.$refs.disabledd.setErrors({
        手機條碼載具: '',
        自然人憑證載具: '',
        公司名稱: '',
        統一編號: ''
      })
    }
  },
  created () {
    this.productId = this.$route.params.id
  },
  mounted () {
    this.GetBirthCheckoutCart()
    this.getCountry()
    this.getTown()
    this.correctInvoice = true // 統編已檢查過不檢查，若有正確資料暫存的狀態直接給正確判定
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/pages/_checkoutcart";

.card {
  .card-header {
    border-radius: 0;
  }
}
.vehicle-input {
  width: 120px;
  @media (max-width: 1200px) {
    width: 100%;
  }
}
.receiptTitle {
  white-space: nowrap;
}
.receipt-card {
  .col-form-label {
    white-space: nowrap;
    @media (max-width: 576px) {
      white-space: wrap;
    }
  }
}
.form-check-input[type="radio"] {
  width: 1em;
  height: 1em;
  margin-top: 0.25rem;
  border-radius: 50%;
}
.form-check-input[type="checkbox"] {
  border-radius: 0;
}
#agreementChecked {
  width: 1em;
  height: 1em;
  margin-top: 0.25rem;
  border-radius: 0.25em;
}
.checks {
  border-radius: 0 !important;
}
</style>
